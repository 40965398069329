<template>
    <div class="main-view">
        <div class="select-title">
            <div style="height: 30px;display: flex;align-items: center;justify-content: space-between;font-size: 13px;padding: 5px 20px;">
                <span style="color: #888888;cursor:pointer;" @click="logout">退出登录</span>
                <div @click="showAddDialog" style="color: #7a69be;outline: 1px #7a69be solid;padding: 2px 10px;border-radius: 3px;">添加小号</div>
            </div>
            <div style="font-size: 13px;color: #7a69be;">
                请选择小号进入游戏
            </div>
        </div>

        <div class="main-view-body" style="overflow-y: auto;">
            <div v-for="item in accountList" class="account-item-view" @click="submitSelect(item.account_id + '', item.account_name)">
                <div class="one-line">{{item.account_name}}</div>
                <span v-if="item.account_id === lastLoginId" style="width: 50px; font-weight: normal;font-size: 12px;color: #888888;margin-right: 10px;">上次登录</span>
            </div>
        </div>

        <myloading v-show="showLoading"></myloading>

        <van-dialog v-model="showInputName" :title="dialogTitle" @confirm="submitAdd" show-cancel-button style="color: #333333">
            <div style="padding: 0 15px;margin-bottom: 10px;margin-top: 10px;">
                <van-field  maxlength="10"  placeholder="请输入小号名称" class="input-view" clearable v-model="inputName" />
            </div>
        </van-dialog>

    </div>
</template>

<script>
    import {request} from "@/api/request";
    import myloading from '@/components/myloading'
    import {Toast, Dialog} from "vant";

    export default {
        name: "Account",
        components: {
            myloading
        },
        data: function () {
            return {
                showLoading: false,
                accountList: [],
                selectedId: '',
                inputName: '',
                showInputName: false,
                isAdd: false,
                dialogTitle: '',
                gameId: '0',
                lastLoginId: 0,
            }
        },
        mounted() {
            this.gameId = localStorage.getItem('gameId');
            this.queryAccountList();
        },
        methods: {
            back() {
                this.$parent.$parent.show('mainMenu')
            },
            queryAccountList() {
                this.showLoading = true;
                let options = {
                    url:"/h5/getAccountList",
                    data: {}
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.accountList = res.data.data.accountList;
                        this.lastLoginId = res.data.data.lastLoginId;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            submitSelect(accountId, name) {

                this.showLoading = true;
                let options = {
                    url:"/h5/login/enter",
                    data: {
                        accountId: accountId,
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        localStorage.setItem('accountId', accountId);
                        this.$parent.$parent.setGameUrl(res.data.data.loginUrl);
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            showAddDialog() {
                this.dialogTitle = '添加小号';
                this.inputName = '';
                this.isAdd = true;
                this.showInputName = true;
            },
            showUpdateNameDialog(id, name) {
                this.dialogTitle = '修改小号名称';
                this.selectedId = id;
                this.inputName = name;
                this.isAdd = false;
                this.showInputName = true;
            },
            submitAdd() {
                if (!this.inputName) {
                    Toast('请输入小号名称');
                    return;
                }
                this.showLoading = true;
                let options = {
                    url:"/sdk/h5/addAccount",
                    data: {
                        accountName: this.inputName.trim(),
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('小号创建成功');
                        this.queryAccountList();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            logout() {
                this.$parent.$parent.logout();
            },
        }
    }
</script>

<style scoped>
    .main-view {
        height: 300px;
        padding: 0 5px;
        user-select: none;
    }
    .select-title {
        position: relative;
        color: #333333;
    }
    .main-view-body {
        padding: 0 20px;
        height: 240px;
    }
    .account-item-view {
        background-color: #f7f7f7;
        height: 35px;
        margin-top: 5px;
        border-radius: 3px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        color: #333333;
    }
    .current-view  {
        font-weight: bold;
        background-color: rgba(244, 108, 73, 0.1);
    }
    .account-item-right {
        display: flex;
        align-items: center;
    }
    .main-button {
        background: #7a69be;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        font-size: 12px;
        width: 75px;
        font-weight: bold;
        border-radius: 3px;
        cursor: pointer;
        user-select: none;
    }

    .one-line {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>